import React from 'react';
import DragableList from './dragableList';
import styles from './drink.module.scss';

export default function Drink(props:any){
    const {foodList} = props;
    return(<div className={styles.snackSection}>
        <div className={styles.topTitle}>
          <span>Drink</span>
        </div>
        <div className={styles.topSectionContentBorder1}>
            <DragableList 
            foodList={foodList}
            category="DRINK"
            droppableId="drinkList"
            ></DragableList>
        </div>
      </div>);
}