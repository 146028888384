/*
https://bulma.io/
*/
import React, { useState } from 'react';
import { Droppable,Draggable} from 'react-beautiful-dnd';
import NumericInput from 'react-numeric-input';
import {Dropdown} from '../common/dropdown';
import {locationMenu} from '../common/parameters';
import 'bulma/css/bulma.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './inventoryPrepareList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {IRootState} from '../store';
import {updatePrepareInventoryRecord} from '../inventory/action/action';
import {addInventoryRecordsThunk} from '../inventory/thunk/thunk';
import { Container, Row, Col } from 'reactstrap';

export default function InventoryPrepareList(props:any){
    const {title,droppableId,stockLocation} = props;
    const prepareInvList = useSelector((state:IRootState)=>state.tecStore.prepareInventories);
    const foodInfoList= useSelector((rootState:IRootState)=>rootState.tecStore.tecStore);

    const [invLocation, setInvLocation] = useState(locationMenu.tw27);

    const dispatch = useDispatch();
    return (<Container className={styles.sectionHalf} fluid={true}>
        <Row>
            <Col>
            <div className={styles.title}>{title}</div>
            </Col>
        </Row>
        <Row>
            <Col className={styles.content1}>
            <Droppable key={droppableId} droppableId={droppableId}>
                {
                    (contentProvider)=>(
                        prepareInvList[stockLocation].length > 0 ?
                        <div className={styles.droppable} ref={contentProvider.innerRef}
                        {...contentProvider.droppableProps}>
                            {
                                prepareInvList[stockLocation].map((inventory:any,index:number)=>(
                                    <Draggable key={`${droppableId}_stock#${stockLocation}_${inventory.stockId}_${index}`} 
                                    draggableId={`stock#${stockLocation}_${inventory.stockId}`}
                                    index = {index}>
                                        {
                                            (contentProvider)=>(
                                                <div
                                                     className={styles.stockContent} 
                                                     key={`draggable_${droppableId}_stock#${stockLocation}_${inventory.stockId}_${index}`} 
                                                     ref={contentProvider.innerRef}
                                                     {...contentProvider.draggableProps}
                                                     {...contentProvider.dragHandleProps}>
                                                         <div className={styles.stockContentInfo}>
                                                            <div className={styles.foodName}>{
                                                                foodInfoList.filter(x=>x.id === inventory.stockId)[0].foodName
                                                            }</div>
                                                            <div className={styles.qty}>
                                                            <Dropdown renderTrigger={({ isOpen, toggle }) => (
                                                                        <button className="button" onClick={toggle}>
                                                                        {/*inventory.location*/invLocation}
                                                                        </button>
                                                                      )}
                                                                      renderContent={({ close }) => (<ul className="menu-list">
                                                                          {
                                                                              Object.values(locationMenu).map((location)=>(
                                                                                  <li 
                                                                                    key={location as string}
                                                                                    onClick={() => {
                                                                                        //dispatch(updatePrepareInventoryRecord(stockLocation,inventory.stockId,location as string,inventory.quantity));
                                                                                        setInvLocation(location as any);
                                                                                    }}>
                                                                                      <a href="#0"
                                                                                         className= {inventory.location === location?'is-active':''}>
                                                                                          {location as string}
                                                                                      </a>
                                                                                  </li>
                                                                              ))
                                                                          }
                                                                      </ul>)}/>
                                                            </div>
                                                            <div className={styles.addQty}>
                                                                <NumericInput mobile 
                                                                className={styles.inventoryQtyDrp} 
                                                                style={{wrap: {fontSize: 25}}} 
                                                                min={0} max={10} 
                                                                onChange={(value:any)=>{
                                                                    dispatch(updatePrepareInventoryRecord(stockLocation,inventory.stockId,invLocation, value));
                                                                }}
                                                                value={inventory.quantity}/>
                                                            </div>
                                                         </div>
                                                </div>
                                            )
                                        }
                                    </Draggable>
                                ))
                            }
                            {contentProvider.placeholder}
                        </div>
                        :(
                            <div className={styles.listPlaceHolder}
                                                ref={contentProvider.innerRef}
                                                {...contentProvider.droppableProps}>
                                                <span>Drag and Drop item here</span>
                                            </div>
                        )
                    )
                }
            </Droppable>
            <div className={styles.submit}>
            <button onClick={async ()=>{
                dispatch(addInventoryRecordsThunk(prepareInvList,stockLocation));
            }}>Update</button>
        </div>
            </Col>
        </Row>
    </Container>);
}