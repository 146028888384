export const shopQuery = `
query($inventoryLocation:STORE_LOCATION!){
    products{
      id
      category
      foodName
      imageUrl
      price
      unit
      inventory(location:$inventoryLocation){
        product{
            id
          }
        quantity
        remainQuantity
      }
    }
  }
`

export const paymentQuery = `
mutation($records:[AddPaymentRecordInput!]!,$totalAmount:Float!,$paymentMethod:PAYMENT_METHOD!,$tokenId:String!){
    payment(records:$records,totalAmount:$totalAmount,paymentMethod:$paymentMethod,tokenId:$tokenId){
      id
      refNo
      approval
      date
      records{
        id
        product{
          id
          foodName
          category
          price
          unit
          imageUrl
          stocks{
            location
            quantity
            histories{
              location
              addedQuantity
            }
          }
          inventories{
            product{
                id
            }
            location
            quantity
            histories{
              location
              addedQuantity
            }
          }
        }
        quantity
        amount
      }
    }
  }
`

export const stockQuery= `
query ($inventoryLocation: STORE_LOCATION!) {
    products {
      id
      category
      foodName
      imageUrl
      price
      unit
      stocks{
        location
        quantity
      }
      inventories{
        location
        remainQuantity
        quantity
        product{
            id
        }
      }
      inventory(location: $inventoryLocation) {
        quantity
        remainQuantity
        histories {
          location
          addedQuantity
          stock {
            location
            quantity
          }
        }
      }
    }
  }  
`

export const addStockRecordQuery=`
mutation ($record: AddStockRecordInput!) {
    addStockRecord(record: $record) {
      location
      quantity
    }
  }
`

export const addInventoryRecordsQuery=`
mutation ($records: [AddInventoryRecordInput!]!) {
    addInventoryRecords(records: $records) {
      location
      quantity
      product{
        id
      }
      histories{
        stock{
          location
          quantity
          histories{
            location
            addedQuantity
          }
        }
        location
        addedQuantity
      }
    }
  }
`

export const addItemQuery=`mutation($item: ProductInput, $file: Upload) {
  addItem(item: $item, file: $file) {
    id
    foodName
    category
    price
    unit
    imageUrl
  }
}`;


export const addCashRecord=`mutation($record:AddPaymentRecordInput!,$totalAmount:Float!,$tokenId:String!){
  addCashRecord(record:$record,totalAmount:$totalAmount,tokenId:$tokenId){
    id
    totalPrice
    paymentMethod
    creditCardNo
    date
    refNo
    approval
  }
}`;
