/*
https://react.school/ui/button
https://stackoverflow.com/questions/3397113/how-to-remove-focus-border-outline-around-text-input-boxes-chrome
*/
import React from 'react';
import Header from './header';
import styles from './location.module.scss';

export default function Location() {
    return (
        <div className={styles.main}>
            <div className={styles.topSection}>
                <div className={styles.row} ><Header login={false} location={''} /></div>
            </div>
            <div className={styles.bottomSection}>
            <div className={styles.instructionDiv}>Please select which campus you here:</div>
            <div className={styles.locationSection}>
                    <a href="/shop/tw27">
                        <button>Tsuen Wan (27/F)</button>
                    </a>
                    <a href="/shop/tw5">
                        <button>Tsuen Wan (5/F)</button>
                        </a>
                    <a href="/shop/sw">
                        <button>Sheung Wan</button>
                    </a>
                </div>
            </div>
        </div>);
}