import React from 'react';
import Header from '../shop/header';
//import Menu from '../stock/menu';
import {useDispatch,useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import styles from './transactionRecords.module.scss';
import { IRootState } from '../store';

export default function TransactionRecords(){
    const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
    const dispatch = useDispatch();
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.row} >
                        <Header login={isAuthenticated} location={``} />
                    </div>
                </div>
                <div className={styles.contentSection}>
                    <a href="/addCash">
                        <button className={styles.addButton} onClick={() => {
                            dispatch(push("/addCash"));
                        }}>Add Cash Record</button>
                    </a>
                </div>
            </div>
        </div>
    )
}