import React from 'react';
import {useSelector} from 'react-redux';
import {IRootState}  from '../src/store';
import {Route,Redirect} from 'react-router-dom';

export function PrivateRoute({ component, ...rest }:any){
    const isAuthenticated = useSelector((state:IRootState)=>state.tecStore.isAuthenticated);
    const Component = component;
    if (Component == null) {
        return null;
    }
    let render:(props:any)=>JSX.Element 
    if(isAuthenticated){
        render = (props:any)=>(
            <Component {...props} />
        )    
    }else{
        render = (props:any)=>(
            <Redirect to={ {
                pathname: '/login',
                state: { from: props.location }
            } } />
        )
    }
    return <Route {...rest} render={render}/>
};