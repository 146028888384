import {ThunkDispatch} from '../../store';
import {STOCK_LOCATION, STORE_LOCATION,locationIdxMenu} from '../../common/parameters';
import {addInventoryRecordsQuery} from '../../tools/queries';
import {updateInventoryRecords} from '../../inventory/action/action';
import fetch from '../../tools/graphqlFetch';
import * as R from 'ramda';

export function addInventoryRecordsThunk(prepareInvList:{
    [name:string]:{
        stockId:string,
        location:string,
        quantity:number
    }[]
},stockLocation:string){
    return async(dispatch:ThunkDispatch)=>{
        const records = prepareInvList[stockLocation].map((record:any)=>({
            stockLocation:STOCK_LOCATION[stockLocation],
            storeLocation:STORE_LOCATION[locationIdxMenu[record.location]],
            productId:parseInt(record.stockId),
            quantity:record.quantity
        }));
        const variables = {records};
        const results = await fetch(addInventoryRecordsQuery,variables);
        dispatch(updateInventoryRecords(stockLocation,R.path(["data","addInventoryRecords"],results)));//Aim: Clear the prepareInvList ONLY
    }
}