import React from 'react';
import {Dropdown} from '../common/dropdown';
import {stockLocationList} from '../common/parameters';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import 'bulma/css/bulma.css';
import styles from './stockHeader.module.scss';
import { useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import { Container, Row, Col } from 'reactstrap';
import {logout} from '../inventory/action/action';

export default function StockHeader(props:any){
    const {login,location} = props;
    const dispatch = useDispatch();
    return (<Container>
        <Row>
            <Col xs="2">
                <div className={styles.logoSection} style={{ marginTop: "20px" }}>
                    <img src="/images/logo_title.png" alt="Tecky Logo" />
                </div>
            </Col>
            <Col xs="4">
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className={styles.header}>
                <div className={styles.title}>Tec 記士多</div>
            </div>
        </div>
            </Col>
            <Col xs="4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            {location && <Dropdown renderTrigger={({isOpen,toggle})=>(
                                <button className="button" 
                                style={{fontWeight:"bold",backgroundColor:"transparent",border:"#1B7A76 dashed 3px",color:"white"}} 
                                onClick={toggle}>
                                    <span>{location}</span>
                                </button>
                            )}  
                            renderContent={({close})=>(
                                <ul className="menu-list">
                                    {
                                        Object.keys(stockLocationList).map((stockLocationKey:any)=>(
                                            <li key={stockLocationList[stockLocationKey]} onClick={()=>{
                                                dispatch(push(`/addInventory/${stockLocationKey}`))
                                            }}>
                                                <a href="#0" className={location === stockLocationList[stockLocationKey]?'isActive':''}
                                                >{stockLocationList[stockLocationKey] as string}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            )}/>}
            </Col>
            <Col xs="2">
            {
            !login?<div className={styles.loginSection} style={{marginTop:"20px",marginLeft:"20px"}}>
            <a href="/login">
                        <button className={styles.loginButton}>Login</button>
            </a>
        </div>:<div className={styles.loginSection} style={{marginTop:"20px",marginLeft:"20px"}}>
            <a href="/login">
            <button className={styles.loginButton} onClick={()=>{
                                dispatch(logout());
                            }}>Logout</button>
            </a>
        </div>
        }
            </Col>
        </Row>
    </Container>);
}