import React, { useState } from 'react';
import { Droppable,Draggable} from 'react-beautiful-dnd';
import {IRootState} from '../store';
import {useSelector} from 'react-redux';
import {locationMenu,locationIdxMenu,STORE_LOCATIONS} from '../common/parameters';
import {Dropdown} from '../common/dropdown';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './inventoryList.module.scss';
import { Container, Row, Col } from 'reactstrap';

export default function InventoryList(props:any){
    const {title, droppableId,location} = props;
    const [ selectedLocation, setSelectedLocation ] = useState("tw27");
    const invList= useSelector((rootState:IRootState)=>rootState.tecStore.tecStore);
    return (<Container className={styles.sectionHalf}>
        <Row className={styles.title}>
            <Col xs="12" sm="6">
                <div>{title}</div>
            </Col>
            <Col xs="12" sm="6" style={{display:"flex", justifyContent:"flex-end"}}>
            <Dropdown renderTrigger={({isOpen,toggle})=>(
                    <button className="button" onClick={toggle}>{locationMenu[selectedLocation]}</button>
                )}
                
                renderContent={({ close }) => (<ul className="menu-list">
                {
                    Object.keys(locationMenu).map((siteKey)=>(
                        <li 
                          key={`invList_drp_${locationMenu[siteKey] as string}`}
                          onClick={() => {
                              setSelectedLocation(siteKey);
                          }}>
                            <a href="#0">
                                {locationMenu[siteKey] as string}
                            </a>
                        </li>
                    ))
                }
            </ul>)}/>
            </Col>
        </Row>
        <Row>
            <Col className={styles.content1}>
            <Droppable key={droppableId} droppableId={droppableId}>
                {
                     (contentProvider)=>(
                        <div className={styles.droppable} ref={contentProvider.innerRef}
                        {...contentProvider.droppableProps}>
                            {
                                invList.map(food=>{
                                    return food.inventories.filter(inv=>inv.location === STORE_LOCATIONS[locationIdxMenu[locationMenu[selectedLocation]]]).map((inventory,index)=>(
                                        <Draggable key={`invList_${food.id}_${location}_${index}`} 
                                    draggableId={`${droppableId}_${food.id}`}
                                    index = {index}>
                                        {
                                            (contentProvider)=>(
                                                <div
                                                     className={styles.stockContent} 
                                                     key={`${droppableId}_${food.id}`} 
                                                     ref={contentProvider.innerRef}
                                                     {...contentProvider.draggableProps}
                                                     {...contentProvider.dragHandleProps}>
                                                          <div className={styles.stockContentInfo}>
                                                          <div className={styles.foodName}>{food.foodName}</div>
                                                          <div className={styles.location}>{inventory.location}</div>
                                                          <div className={styles.qty}>{inventory.quantity}</div>
                                                          </div>
                                                     </div>
                                            )
                                        }
                                    </Draggable>
                                    ))
                                })
                            }
                        </div>
                     )
                }
            </Droppable>

            </Col>
        </Row>
    </Container>);
}