/*
https://github.com/fabiobiondi/react-circular-menu
https://www.npmjs.com/package/react-sidebar
https://github.com/innFactory/react-planet
https://github.com/supasate/connected-react-router/issues/312
*/
import React, { useEffect, useState } from 'react';
import Header from '../shop/header';
import Menu from './menu';
import LocationMenu from './locationMenu';
import DrinkInfoBlock from './drinkInfoBlock';
import NoodlesInfoBlock from './noodlesInfoBlock';
import SnackInfoBlock from './snackInfoBlock';
import styles from './stock.module.scss';
import {stockQuery} from '../tools/queries';
import fetch from '../tools/graphqlFetch';
import Food from '../model/food';
import * as R from 'ramda';
import {STORE_LOCATIONS,locationIdxMenu,locationMenu} from '../common/parameters';
import { useDispatch,useSelector } from 'react-redux';
import {addFoodList} from '../inventory/action/action';
import {IRootState} from '../store';

  export default function Stock() {
  const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
  const [stockList, setStockList] = useState<Food[]>([]);
  //const foodList = useSelector((state:IRootState)=>state.tecStore.tecStore);
  const dispatch = useDispatch();
  useEffect(()=>{
    (async()=>{
      const variables = {"inventoryLocation": "TSUEN_WAN_27F"}
      const stocks = await fetch(stockQuery,variables);
      setStockList(R.path(["data","products"],stocks) as Food[]);
      dispatch(addFoodList(R.path(["data","products"],stocks) as Food[]));
    })();
  },[dispatch]);


  
    const [openMenu, setOpenMenu] = useState(false);
    const onLocationSelected = async (key:string)=>{
        const variables = {"inventoryLocation": STORE_LOCATIONS[locationIdxMenu[locationMenu[key]]]};
        const stocks = await fetch(stockQuery,variables);
        setStockList(R.path(["data","products"],stocks) as Food[]);
    };
    const showBackgroud = ()=>{
        setOpenMenu(true);
    };
    const closeBackgroud = ()=>{
        setOpenMenu(false);
    };
    return (
        <div className={styles.root}>   
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.row} ><Header login={isAuthenticated} location={''} /></div>
                </div>
                <div className={styles.overlapDiv}>
                    <div className={styles.bottomSection} onMouseOver={showBackgroud} onMouseLeave={closeBackgroud}>
                        <Menu />
                    </div>
                    <div className={styles.locationMenu} onMouseOver={showBackgroud} onMouseLeave={closeBackgroud}>
                        <LocationMenu onLocationSelected={onLocationSelected} />
                    </div>
                    <div className={styles.stockInfoSection}>
                       {
                           stockList.map(stock=>(
                               stock.category === "DRINK" ? 
                               <DrinkInfoBlock key={stock.id} food={stock}/>:(
                                   stock.category === "SNACK"? 
                                        <SnackInfoBlock key={stock.id} food={stock}/> : 
                                            <NoodlesInfoBlock key={stock.id} food={stock}/>)
                           ))
                       }
                    </div>
                </div>
                {openMenu ? <div className={styles.modalBox}></div> : <></>}
            </div>
        </div>
    );
}