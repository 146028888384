import React from 'react';
import DragableList from './dragableList';
import styles from './noodles.module.scss';

export default function Noodles(props:any){
    const {foodList} = props;
    return(<div className={styles.snackSection}>
        <div className={styles.topTitle}>
          <span>Noodles</span>
        </div>
        <div className={styles.topSectionContentBorder3}>
        <DragableList 
            foodList={foodList}
            category="NOODLES"
            droppableId="noodlesList"
            ></DragableList>
        </div>
      </div>);
}