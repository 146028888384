import React, {useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import {DragDropContext,DropResult} from 'react-beautiful-dnd';
import Food from '../model/food';
import BoughtItem from '../model/boughtItem';
import ShoppingCartList from './shoppingCartList';
import DrinkList from './drink';
import SnackList from './snack';
import NoodlesList from './noodles';
import PaymentSection from './stripeContainer';
import Header from './header';
import Instruction from './instruction';
import {locationMap,locationMenu,locationIdxMenu,STORE_LOCATIONS} from '../common/parameters';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './shop.module.scss';

import fetch from '../tools/graphqlFetch';
import {shopQuery} from '../tools/queries';
import * as R from 'ramda';
import { IRootState } from '../store';
import {updateFoodListQty,addFoodList} from '../inventory/action/action';
import { Container, Row, Col } from 'reactstrap';

function App(){
  //const [foodList,] = useState<Food[]>(foodInfoList as Food[]);
  //const [foodList,setFoodList] = useState<Food[]>([]);
  const dispatch = useDispatch();
  let foodList = useSelector((root:IRootState)=>root.tecStore.tecStore) as any;
  const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
  const [shoppingCart,setShoppingCart] = useState<BoughtItem[]>([]);
  const [totalPrices, setTotalPrices] = useState(0.0);
  const {match:{params:{location}}} = useReactRouter<{location:string}>();

  const onDragEnd = (result:DropResult)=>{
    const {source, destination} = result;
    if (!destination) return;
    if(shoppingCart.findIndex(element=>element.food.id === foodList[source.index].id)>-1) return;
    if((source.droppableId === "drinkList" || 
        source.droppableId === "snackList" || 
        source.droppableId === "noodlesList")&&(destination.droppableId === "shoppingList")){
          let newshoppingCart = shoppingCart.slice(0);
          newshoppingCart.push({food:foodList[source.index],quantityInCart:1});
          setShoppingCart(newshoppingCart);
          setTotalPrices(totalPrices + foodList[source.index].price);
          dispatch(updateFoodListQty(source.index,-1));
    }else{
      console.log("Please drag and drop from above 3 columns.")
    }
  }
  
  const removeItemFromCart = (index:number)=>{
    const delFood = shoppingCart[index];

    const updatedFoodList = foodList.slice(0);
    const idx = updatedFoodList.findIndex((item:Food)=>item.id === delFood.food.id);
    //if (updatedFoodList[idx].inventory.remainQuantity <=0) return;
    dispatch(updateFoodListQty(idx,delFood.quantityInCart));

    const delAmount = delFood.food.price * delFood.quantityInCart;
    setTotalPrices(totalPrices - delAmount);
    shoppingCart.splice(index,1);
    setShoppingCart(shoppingCart.slice(0));
  }

  const onQuantityChanged = (value:number, shoppingCartIdx:number)=>{
    const updatedShoppingCartList = shoppingCart.slice(0);
    const updatedTarget = updatedShoppingCartList[shoppingCartIdx];

    let updatedFoodList:Food[] = foodList.slice(0);
    const idx = updatedFoodList.findIndex((item:Food)=>item.id === updatedTarget.food.id);

    if (updatedFoodList[idx].inventory.remainQuantity>0){
      dispatch(updateFoodListQty(idx,updatedTarget.quantityInCart - value));
    }else if((updatedFoodList[idx].inventory.remainQuantity==0) && (updatedTarget.quantityInCart > 0 && updatedTarget.quantityInCart > value)){
      dispatch(updateFoodListQty(idx,updatedTarget.quantityInCart - value));
    }else{
      return;
    }

    let resetTotalPrices = totalPrices - updatedTarget.food.price * updatedTarget.quantityInCart;
    updatedTarget.quantityInCart = value;
    let newTotalPrices = resetTotalPrices + updatedTarget.food.price * updatedTarget.quantityInCart;
    setTotalPrices(newTotalPrices);
    setShoppingCart(updatedShoppingCartList);
    return;
  }

  const resetHandler = ()=>{
    setShoppingCart([]);
    setTotalPrices(0);
  }

  useEffect(()=>{
    (async()=>{
      let variables = {
        inventoryLocation:STORE_LOCATIONS[locationIdxMenu[locationMenu[location]]] 
      };
      const results = await fetch(shopQuery,variables);
      const foods = R.path(["data","products"],results);
      dispatch(addFoodList(foods as Food[]));
    })();
  },[dispatch]);

  return (
    <div className={styles.root}>
    <DragDropContext onDragEnd={onDragEnd}>
    <Container className={styles.main} fluid={true}>
    <div className={styles.topSection}>
      <Row>
        <Col>
          <div className={styles.row} ><Header login={isAuthenticated} location={`${locationMap[location]}`}/></div>
        </Col>
      </Row>
      <Row>
        <Col><div className={styles.row}><Instruction/></div></Col>
      </Row>
      <Row>
      <Col xs="12" sm="4"><DrinkList foodList = {foodList}/></Col>
      <Col xs="12" sm="4"> <SnackList foodList = {foodList}/></Col>
      <Col xs="12" sm="4"><NoodlesList foodList ={foodList}/></Col>
    </Row>
    </div>
    <Row style={{height:"50%"}}>
      <Col xs="12" sm="6">
            <PaymentSection totalPrices={totalPrices} 
                            location={`${locationMenu[location]}`} 
                            shoppingCart={shoppingCart}
                            resetHandler= {resetHandler} />
      </Col>
      <Col xs="12" sm="6">
              <ShoppingCartList
                    shoppingCart={shoppingCart}
                    removeItemFromCart={removeItemFromCart}
                    onQuantityChanged={onQuantityChanged}>
                    </ShoppingCartList>
          
      </Col>
    </Row>    
  </Container>
  </DragDropContext>
    </div>
    );
}

export default App;
