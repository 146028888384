import {RouterState,connectRouter,routerMiddleware,CallHistoryMethodAction} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {ITecStoreState} from './inventory/state/state';
import {tecStoreReducer} from './inventory/reducer/reducer';
import {ITecStoreAction} from './inventory/action/action';
import thunk, {ThunkDispatch as OldThunkDispatch} from 'redux-thunk';
import StateLoader from './stateLoader';

export type ThunkDispatch = OldThunkDispatch<IRootState,null,IRootAction>;

export const history = createBrowserHistory();
export const stateLoader = new StateLoader();

export interface IRootState{
    tecStore:ITecStoreState,
    router: RouterState
}

const rootReducer = combineReducers<IRootState>({
    tecStore:tecStoreReducer,
    router: connectRouter(history)
});

type IRootAction = ITecStoreAction | CallHistoryMethodAction;
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default createStore<IRootState,IRootAction,{},{}>(
    rootReducer,
    composeEnhancers( 
        applyMiddleware(routerMiddleware(history)),
        applyMiddleware(thunk)
    )
);
stateLoader.loadState();
