import React, { useState } from 'react';
import { Droppable,Draggable} from 'react-beautiful-dnd';
import Food from '../model/food';
import NumericInput from 'react-numeric-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './stockList.module.scss';
import { useSelector } from 'react-redux';
import {IRootState} from '../store';
import {STOCK_LOCATION} from '../common/parameters';

export default function StockList(props:any){
    const {title,category,droppableId,location,updatedStock} = props;
    const stockList = useSelector((state:IRootState)=>state.tecStore.tecStore);
    const [newStockItemList, setNewStockItemList] = useState(Array(stockList.length).fill(0));

    return (
    <div className={styles.section}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
            <Droppable key={droppableId} droppableId={droppableId}>
                {
                    (contentProvider)=>(
                        <div key={`${droppableId}_${category}`} 
                        className={styles.droppable} 
                        ref={contentProvider.innerRef}
                        {...contentProvider.droppableProps}>
                            {
                                stockList.filter((food:Food)=>food.category===category).map((stock:Food,index:number)=>(
                                    <Draggable key={`draggable_${category}_${stock.id}_${index}`} 
                                    draggableId={`${category}_${stock.id}`}
                                    index = {stockList.findIndex((food:Food)=>food.id === stock.id)}>
                                        {
                                            (contentProvider)=>(
                                                <div
                                                     className={styles.stockContent} 
                                                     key={`div_${category}_${stock.id}`} 
                                                     ref={contentProvider.innerRef}
                                                     {...contentProvider.draggableProps}
                                                     {...contentProvider.dragHandleProps}>
                                                         <div className={styles.stockContentInfo} key={index}>
                                                            <div key={`${category}_foodName_${index}`} className={styles.foodName}>{stock.foodName}</div>
                                                            <div key={`${category}_${stock.id}_qty_${index}`} className={styles.qty}>
                                                                {
                                                                    stock.stocks.filter(stockRec=>stockRec.location === STOCK_LOCATION[location]).length > 0?
                                                                        //Show the item
                                                                        stock.stocks.filter(stockRec=>stockRec.location === STOCK_LOCATION[location]).map((stockRec)=>(<div key={`${category}_qtyRec_${index}`}>
                                                                            {stockRec.quantity}
                                                                            </div>))
                                                                    :
                                                                        //Add the new item if it doesn't exist
                                                                        <div key={`${category}_qtyRecDummy_${index}`}>
                                                                            0
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div  key={`${category}_qty_${index}`} className={styles.addQty}>
                                                                <NumericInput mobile 
                                                                className={styles.inventoryQtyDrp} 
                                                                style={{wrap: {fontSize: 25}}} min={0} max={100}
                                                                onChange={async(value)=>{
                                                                    let newStockItemCountList = newStockItemList.slice(0);
                                                                    newStockItemCountList[index] = value;
                                                                    setNewStockItemList(newStockItemCountList);
                                                                }} 
                                                                value={newStockItemList[index]}/>
                                                            </div>
                                                            <div className={styles.confirmBtn}>
                                                                { newStockItemList[index] > 0  && <button onClick={() => {
                                                                    updatedStock(stock.id,newStockItemList[index].toString());
                                                                    let newStockItemCountList = newStockItemList.slice(0);
                                                                    newStockItemCountList[index] = 0;
                                                                    setNewStockItemList(newStockItemCountList);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faSignInAlt} />
                                                                </button>}
                                                            </div>
                                                         </div>
                                                </div>
                                            )
                                        }
                                    </Draggable>
                                ))
                            }
                            {contentProvider.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </div>
    </div>
    );
}