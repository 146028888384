/*
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
 */
//import { foodInfoList } from '../../common/parameters';
import { ITecStoreState } from '../state/state';
import { ITecStoreAction } from '../action/action';
import {stockLocationList,STOCK_LOCATION,STORE_LOCATIONS,locationIdxMenu} from '../../common/parameters';
import Food from '../../model/food';
import {stateLoader} from '../../store';

export const initialState = {
    tecStore: [/*...foodInfoList*/],
    prepareInventories: {
        ...Object.fromEntries(Array.from(Object.keys(stockLocationList)).map(data=>{
            return[
                [data],[]
            ]
        }))
    },
    isAuthenticated:undefined
}

export const tecStoreReducer = (state: ITecStoreState = stateLoader.loadState(), action: ITecStoreAction) => {
    switch (action.type) {
        case "ADD_FOOD_LIST":{
            return {
                ...state,
                tecStore:action.foodItems,
                isAuthenticated:state.isAuthenticated
            }
        }
        case "UPDATE_FOOD_QTY":{
            let newTecStore = state.tecStore.slice(0);
            let food = newTecStore[action.index];
            // action.quantity = -1
            // action.quantity = delAmount
            // action.quantity = updatedTarget.quantityInCart - value
            food.inventory.remainQuantity = food.inventory.remainQuantity + action.quantity;
            return {
                ...state,
                tecStore: newTecStore,
                isAuthenticated:state.isAuthenticated
            }
        }
        case "UPDATE_STOCK": {
            let newTecStore = state.tecStore.slice(0);
            let targetStock:Food[] = newTecStore.filter(food => food.id === action.stockId);
            if (targetStock[0].stocks.filter((s:any)=>s.location === action.stockLocationId).length === 0) {
                targetStock[0].stocks.push({
                    location: action.stockLocationId,
                    quantity: action.stockQty,
                    histories: [{
                        location: action.stockLocationId,
                        addedQuantity: action.stockQty
                    }]
                });
            } else {
                let curStock = state.tecStore.filter(food => food.id === action.stockId)[0].stocks.filter(stock => stock.location === action.stockLocationId);
                let newStock = newTecStore.filter(food => food.id ===  action.stockId)[0].stocks.filter(stock => stock.location === action.stockLocationId);
                newStock[0].quantity = curStock[0].quantity + action.stockQty;

                //Keep History
                if (!newStock[0].histories)newStock[0].histories=[];
                newStock[0].histories.push({
                    location: action.stockLocationId,
                    addedQuantity: action.stockQty
                });
            }
            return {
                ...state,
                tecStore: newTecStore,
                isAuthenticated:state.isAuthenticated
            }
        }
        case "ADD_PREPARE_INV_REC":
            return {
                ...state,
                prepareInventories: {
                    ...state.prepareInventories,
                    [action.stockLocation]:[
                        ...state.prepareInventories[action.stockLocation],
                        {
                            stockId: action.stockId,
                            location: action.storeLocation,
                            quantity: action.quantity
                        }
                    ]

                },
                isAuthenticated:state.isAuthenticated
            }
        case "UPDATE_PREPARE_INV_REC":
            if (state.prepareInventories) {
                //Update stock (-qty)
                let newFoodListIdx = state.tecStore.findIndex(food=>food.id === action.stockId);
                let newFoodList = state.tecStore.slice(0);
                let newStockListIdx = newFoodList[newFoodListIdx].stocks.findIndex(stock=>stock.location === STOCK_LOCATION[action.stockLocation]);
                if (newFoodList[newFoodListIdx].stocks[newStockListIdx].quantity - 1 >= 0){
                    newFoodList[newFoodListIdx].stocks[newStockListIdx].quantity = newFoodList[newFoodListIdx].stocks[newStockListIdx].quantity - 1;
                    //Update inventories (+qty)
                    let newStockLocationInvList = state.prepareInventories[action.stockLocation];
                    const idx = newStockLocationInvList.findIndex(invR => invR.stockId === action.stockId);
                    newStockLocationInvList[idx].location = action.storeLocation;
                    newStockLocationInvList[idx].quantity = action.quantity;

                    return {
                        tecStore:[
                            ...newFoodList
                        ],
                        prepareInventories: {
                            ...state.prepareInventories,
                            [action.stockLocation]: [
                                ...newStockLocationInvList
                            ]
                        },
                        isAuthenticated:state.isAuthenticated
                    }
                }else{
                    return state;
                }
            }
            break;
        case "UPDATE_INV_REC":
            const invAtStock = state.prepareInventories[action.stockLocation].slice(0);
            let newTecStore = state.tecStore.slice(0);
            for (let food of invAtStock) {
                let newFoodRec = newTecStore.filter(f => f.id === food.stockId);
                if (newFoodRec[0].inventories) {
                    const results = action.invRecords.filter((item: any) => item.product.id === food.stockId && item.location === STORE_LOCATIONS[locationIdxMenu[food.location]]);
                    console.log(food.location);
                    console.log(results);
                    const existsRecordIdx = newFoodRec[0].inventories.findIndex((item: any) => item.product.id === food.stockId && item.location === STORE_LOCATIONS[locationIdxMenu[food.location]]);
                    if (existsRecordIdx > -1) {
                        //Exist, remove exist and add new one
                        if (results.length > 0) {
                            newFoodRec[0].inventories = [
                                ...newFoodRec[0].inventories.slice(0, existsRecordIdx),
                                ...results,
                                ...newFoodRec[0].inventories.slice(existsRecordIdx + 1)
                            ]
                        }
                    } else {
                        //No Record, append at the end
                        if (results.length > 0) {
                            newFoodRec[0].inventories = [
                                ...newFoodRec[0].inventories,
                                ...results
                            ];
                        }
                    }
                    //Update qty and add histories
                    /*const idx = newFoodRec[0].inventories.findIndex(x=>x.location === food.location);
                    if (idx > -1){
                        //Exists
                        // newFoodRec[0].inventories[idx].quantity = newFoodRec[0].inventories[idx].quantity + food.quantity;
                        // newFoodRec[0].inventories[idx].histories.push({
                        //     stocks:newFoodRec[0].stocks,
                        //     stock:newFoodRec[0].stocks.filter(item=>item.location === food.location)[0],
                        //     location:food.location,
                        //     addedQuantity:food.quantity
                        // })
                    }else{//No this location INV records
                        //New Object
                        // newFoodRec[0].inventories.push({
                        //     product:newFoodRec[0],
                        //     location: food.location,
                        //     quantity:food.quantity,
                        //     remainQuantity: 0,
                        //     histories:[
                        //         {
                        //             stocks:newFoodRec[0].stocks,
                        //             stock:newFoodRec[0].stocks.filter(item=>item.location === food.location)[0],
                        //             location: food.location,
                        //             addedQuantity:food.quantity
                        //         }
                        //     ]
                        // });
                    }*/
                } else {
                    //Create new object with qty and histories
                    // newFoodRec[0].inventories = [{
                    //     product:newFoodRec[0],
                    //     location: food.location,
                    //     quantity:food.quantity,
                    //     remainQuantity: 0,
                    //     histories:[
                    //         {
                    //             stocks:newFoodRec[0].stocks,
                    //             stock:newFoodRec[0].stocks.filter(item=>item.location === food.location)[0],
                    //             location: food.location,
                    //             addedQuantity:food.quantity
                    //         }
                    //     ]
                    // }]
                    const results = action.invRecords.filter((item: any) => item.product.id === food.stockId && item.location === food.location);
                    if (results.length > 0) {
                        newFoodRec[0].inventories = [...results];
                    }
                }
            }
            return {
                tecStore: [
                    ...newTecStore
                ],
                prepareInventories: {
                    ...state.prepareInventories,
                    [action.stockLocation]: []
                },
                isAuthenticated: state.isAuthenticated
            }
        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true
            }
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: undefined
            }
        case "LOGIN_FAIL":
            return {
                ...state,
                isAuthenticated: false
            }
    }
    return state;
}