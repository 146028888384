import React from 'react';
import { Planet } from 'react-planet';
import {push} from 'connected-react-router';
import styles from './menu.module.scss';
import { useDispatch } from 'react-redux';

export default function Menu(){
    const dispatch = useDispatch();
    return(<Planet centerContent={<div className={styles.centerMenu}>Menu</div>}
        dragablePlanet
        dragRadiusPlanet={20}
        bounce
        hideOrbit
        autoClose
        orbitRadius={200}
        bounceOnClose
        rotation={67}
        bounceDirection="BOTTOM">
        <div key="stock" className={styles.satellite}>
            <button onClick={()=>{
                    dispatch(push("/stock"));
                }
            }>Stock</button>
        </div>
        <div key="addInv" className={styles.satellite}>
        <button onClick={()=>{
                    dispatch(push("/addInventory/tw"));
                }
            }>
            Add Inventory
            </button>
        </div>
        <div key="addItem" className={styles.satellite}>
        <button onClick={()=>{
                    dispatch(push("/addItem"));
                }
            }>Add Item</button>
            </div>
        <div key="trans" className={styles.satellite}>
        <button onClick={()=>{
                    dispatch(push("/transactionsRecords"));
                }
            }>Transactions Records</button>
            </div>
        <div key="salesVolumes" className={styles.satellite}>
            <button onClick={()=>{
                dispatch(push("/salesVolumes/tw27"))
            }}>Sales Volumes Report</button>
        </div>
        <div />
        <div />
        <div />
    </Planet>);
}