import React, { useEffect } from 'react';
import {Droppable,Draggable} from 'react-beautiful-dnd';
import styles from './dragableList.module.scss';
import Food from '../model/food';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { IRootState } from '../store';

export default function DragableList(props:any){
    let {category,droppableId} = props;
    let foodList = useSelector((root:IRootState)=>root.tecStore.tecStore as any);
    const {t} = useTranslation();

    useEffect(()=>{
      console.log(foodList);
    },[]);

    return (
    <Droppable
        key={droppableId}
        droppableId={droppableId}>
            {(drinkProvider)=>(
               <div className={styles.foodListSection} 
               ref= {drinkProvider.innerRef}
               {...drinkProvider.droppableProps}>
                 {
                   foodList.filter((food:Food)=>food.category===category).map((drink:Food,index:number)=>(
                     <Draggable key={drink.id}
                     draggableId={`${drink.id}`} 
                     index={foodList.findIndex((food:Food)=>food.id === drink.id)}
                     isDragDisabled = { drink.inventory === null?true:(drink.inventory.remainQuantity>0?false:true)}
                     >
                       {(drinkInfoProvider)=>(
                         <div key={drink.id} className={styles.foodContent}
                           ref={drinkInfoProvider.innerRef}
                           {...drinkInfoProvider.draggableProps}
                           {...drinkInfoProvider.dragHandleProps}>
                           <img src={drink.imageUrl} alt="" />
                           <div className={styles.img__description}>
                             {
                               drink.inventory === null?(
                                 <div>{t('sell.out')}</div>
                               ):drink.inventory.remainQuantity>0?(
                                   <>
                                     <div className={styles.foodNameText}>{drink.foodName}</div>
                                     <div>${drink.price}/{t(`${drink.unit}`)}</div>
                                   </>
                               ):(
                                <div>{t('sell.out')}</div>
                               )
                             }
                             
                           </div>
                         </div>
                       )}
                     </Draggable>
                   ))
                 }
                 {drinkProvider.placeholder}
               </div>
            )}
          </Droppable>
    )
}