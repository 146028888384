import Food from "../../model/food"

export function addFoodList(foodItems:Food[]){
    return {
        type: "ADD_FOOD_LIST" as "ADD_FOOD_LIST",
        foodItems
    }
}

export function updateFoodListQty(idx:number,quantity:number){
    return {
        type: "UPDATE_FOOD_QTY" as "UPDATE_FOOD_QTY",
        index:idx,
        quantity:quantity
    }
}

// export function addStock(stockQty:number,stockId:number,stockLocationId:string){
//     return {
//         type: "ADD_STOCK" as "ADD_STOCK",
//         stockId: stockId,
//         stockLocationId : stockLocationId,
//         quantity: stockQty
//     }
// }

export function updateStock(stockQty:number,stockId:string,stockLocationId:string){
    return{
        type: "UPDATE_STOCK" as "UPDATE_STOCK",
        stockQty,
        stockId:stockId.toString(),//food.id
        stockLocationId
    }
}

export function addPrepareInventoryRecord(stockLocation:string,
                                            stockId:string,
                                            storeLocation:string,
                                            quantity:number){
    return{
        type: "ADD_PREPARE_INV_REC" as "ADD_PREPARE_INV_REC",
        stockLocation,
        stockId,
        storeLocation,
        quantity
    }
}

export function updatePrepareInventoryRecord(stockLocation:string,
                                            stockId:string,
                                            storeLocation:string,
                                            quantity:number){
    return{
        type: "UPDATE_PREPARE_INV_REC" as "UPDATE_PREPARE_INV_REC",
        stockLocation,
        stockId,
        storeLocation,
        quantity
    }
}

export function updateInventoryRecords(stockLocation:string,invRecords:any){
    return{
        type : "UPDATE_INV_REC" as "UPDATE_INV_REC",
        stockLocation,
        invRecords
    }
}

export function login(){
    return{
        type: "LOGIN" as "LOGIN"
    }
}

export function logout(){
    return{
        type: "LOGOUT" as "LOGOUT"
    }
}

export function loginFail(){
    return{
        type: "LOGIN_FAIL" as "LOGIN_FAIL"
    }
}

type ITecStoreActionCreators = typeof addFoodList | typeof updateFoodListQty /*| typeof addStock*/ | typeof updateStock| typeof addPrepareInventoryRecord | typeof updatePrepareInventoryRecord | typeof updateInventoryRecords | typeof login | typeof logout | typeof loginFail;
export type ITecStoreAction = ReturnType<ITecStoreActionCreators>;