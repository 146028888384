export const locationMap: any = {
  tw27: "- Tsuen Wan 27/F",
  tw5: "- Tsuen Wan 5/F",
  sw: "- Sheung Wan"
};

export const locationMenu: any = {
  tw27: "Tsuen Wan 27/F",
  tw5: "Tsuen Wan 5/F",
  sw: "Sheung Wan"
};

export const stockLocationList:any = {
  sw: "(Sheung Wan Stock)",
  tw: "(Tsuen Wan Stock)"
}

export const STOCK_LOCATION:any = {
  tw:"TSUEN_WAN",
  sw:"SHEUNG_WAN"
}

export enum CATEGORY{
  DRINK,
  SNACK,
  NOODLES
}

export const CATEGORIES:string[] = [
  "DRINK",
  "SNACK",
  "NOODLES"
];

export enum STORE_LOCATION{
  TSUEN_WAN_27F,
  TSUEN_WAN_5F,
  SHEUNG_WAN,
}

export const STORE_LOCATIONS:string[] = [
  "TSUEN_WAN_27F",
  "TSUEN_WAN_5F",
  "SHEUNG_WAN",
]

export const locationIdxMenu: any = {
  "Tsuen Wan 27/F":0,
  "Tsuen Wan 5/F":1,
  "Sheung Wan":2
};

export const UNITS:string[] = [
  "CAN",
  "PACK",
  "BOTTLE",
  "CUP",
  "BAG"
]

//Testing
/*{ products{
     ....
} }*/
export const foodInfoList = [
  {
    "id": 1,
    "foodName": "240ml四洲經典粒粒橙汁",
    "category": "DRINK",
    "price": 5,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://www.parknshop.com/medias/sys_master/front/prd/9314024751134.jpg",
    stocks: [],
    inventories:[],
    //salesVolumes:[{}]
  },
  {
    "id": 2,
    "foodName": "250ml維他-檸檬茶",
    "category": "DRINK",
    "price": 5,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/HqPJu49MoJCUGopn5LhcmDXjPmXI-BuiFsYSFJ40sqKLSrukyN9YwFRg9N3zNXo3QnD3mB8odwABQUDlR_3foszQ6JxpV97c3aTCnpDk_UZgrCtpEff9mhkQZ7U7eaFT7-fplVS7",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 3,
    "foodName": "250ml維他-朱古力奶",
    "category": "DRINK",
    "price": 5,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/XTT9njvKyXOymsOg0GEJ1RZQgmjHGyt_D8LfON-JHqwRT5kfiKlQgu_qSzUTdUAHZhpmoy7dCc8pTUhxtLVi_067MihaKHAzNx-8gHTXNzI7kvHo2ZsE8xH8RyCtGvYZKi6dxC4P",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 4,
    "foodName": "245ml (幼罐)寶礦力水特",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://eshop.dch.com.hk/files/product/1/952424.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 5,
    "foodName": "330ml （罐裝）檸檬茶",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/2Q-AXQPu-3QDUM27Lc5sE4nZHCDT0zHp33E0ZErnBWckJ0RWlxWz7jdkkfPArqs6pOITAoxFV15C6XRdiBUNYY5ztb8cHA4H7Kh-5_o9Jhd-2wBiXRrRAW8_l2i5XqMVSr2SHuU",
    stocks: [],
    inventories:[],
    //salesVolumes:[{}]
  },
  {
    "id": 6,
    "foodName": "330ml(罐裝)可口可樂 - ZERO",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://im1.book.com.tw/image/getImage?i=https://www.books.com.tw/img/N01/100/68/N011006808.jpg&v=57db6e40&w=250&h=250",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 7,
    "foodName": "330ml(罐裝)可口可樂 - 原味",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/z_HPtu1OLXbjFbHyOcolmiDU87w8myHPy-OSdk_uu9h2Uw5BUr4hhx5vZLMFfQ0IxBYsf1rZM3tYGTxS_Y6Y3nGaw6efsDwL1fHlqfjf_UCfMwymheXkur3f-Vxn82v8bZ-4wnw",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 8,
    "foodName": "330ml(罐裝) 忌廉",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/Krhat4SRtT00gS6gum9Uuuvll0aKNKvCSrW4CP0daBXIyLhTqzVeuQa2CKWaoF892HeuUTyubWxExUmPNyDmY2LQWubXzjCpGWa0zgJlbLlwVh0bghsexbS54qQtYSpWWzRqnuM",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 9,
    "foodName": "330ml葡萄適-原味",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/b9EdHBOfaEIRARxvup_bKFfILChHGOzXR4glCA5myVYmvW7SPIhnDu4z98o6dZzqjGz9EFS1s-jxLxHTcaKd4T_PM4XyQUA0el9q2inF0moTg7CQuCPb7XYiGqR5poYwotBA8B_vcM4",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 10,
    "foodName": "S&W (罐裝) 芒果汁",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://www.ckf.com.hk/image/cache/data/dsi551022-500x500.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 11,
    "foodName": "雀巢咖啡。香滑",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/46G8AkgJThWZE4GXChq8Ml45tNbBCSNgTlukfrurMsTQ0FZ8nXDQFYLKAb0hPmcNCIHDEEgS9wuhBWKzaAdXz5iDlliVVd6O_3IX203s_4VFhBbXBfqK0GcKvi5SJGon4SdWSBI",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 12,
    "foodName": "雀巢咖啡。香濃",
    "category": "DRINK",
    "price": 7,
    "unit": "罐",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/23PMJlcZVcu871M4kPAIc4Ac-l1-GXTm1iGKJw8SQghDgIlRw6SnYNGK4ei7y2h_mzRus-rTjqdbsmCGixYbVt5MAaOn0VYYO51TQVudM0UAmO2lKOjPgGwXX9nk4tQtK5ScjZk",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 13,
    "foodName": "500ml津路烏龍茶",
    "category": "DRINK",
    "price": 9,
    "unit": "支",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/f9ueBrue8Ys-MDIlMGpVUyD2ucP4SsSD1szyL5YVGpkgJwBm3eoIE_bTnze_fYNqZe0ROJhzJYHKhG6d8zJcwuFUy-i0VfLlWdd_cM2UfmsyJfZngwkF6wY3jEMZUkiK-BawMkpCV8T4",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 14,
    "foodName": "500ml道地-極品上綠茶",
    "category": "DRINK",
    "price": 9,
    "unit": "支",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/WOonUHEgqkqzUQFZk66MypNiW73oX8FyEBncpC5e4JtiDY26vReZzrxEnp9ykNGalUDA57nynUtBg55rkrDoJbN3tbOCbIA0F0HrbPfpURCwhRR4y2tr9eM5MEiJxbFHpYkVaK8m-pA",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 15,
    "foodName": "媽咪杯。咖哩喇沙",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/jrLYvgqF_yibqeST61-8jckcAvbI2MaFM5OanxlU7ImeKQEMtVSF3yB9kbv8zEGZ8KKU-Y3oridq6orEnaBwHGknGXX8Yk9bobMpfqPiFu35JnT-65HCmX1VIhjBmaIgKHY",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 16,
    "foodName": "日清出前一丁碗麵。麻油味",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://www.nissinfoods.com.hk/f/products/89/sesame-bowl_1523936829.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 17,
    "foodName": "日清出前一丁碗麵。豬骨味",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://www.nissinfoods.com.hk/f/products/144/tonkotsu-bowl_1523938376.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 18,
    "foodName": "合味道杯麵。咖喱",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://www.nissinfoods.com.hk/f/products/129/425p425/coc_760_1_1523934077.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 19,
    "foodName": "合味道杯麵。五香牛肉",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://www.nissinfoods.com.hk/f/products/134/425p425/cob_760_1523935049.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 20,
    "foodName": "合味道杯麵。海鮮",
    "category": "NOODLES",
    "price": 9,
    "unit": "個",
    "quantity": 10,
    "imageUrl": "https://www.parknshop.com/medias/sys_master/front/prd/9156216061982.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 21,
    "foodName": "利是 夾心餅。芝士味",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.parknshop.com/medias/sys_master/front/prd/9392092938270.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 22,
    "foodName": "Oreo夾心餅-原味",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://cbu01.alicdn.com/img/ibank/2020/681/608/17925806186_821672513.220x220.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 23,
    "foodName": "固力果百奇餅乾條-沙律",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.parknshop.com/medias/sys_master/front/prd/9396796293150.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 24,
    "foodName": "固力果百奇餅乾條-草莓",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/8e8/95e/701/8171d582808a71adc1a0b7d.jpg_600_0_3_76ee.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 25,
    "foodName": "韓國烤紫菜。原味",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.ckf.com.hk/image/cache/data/nkh002a-500x500.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 26,
    "foodName": "韓國烤紫菜。芥末",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.ckf.com.hk/image/cache/data/nkh818818-500x500.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 27,
    "foodName": "迷你烤多士 - 蒜蓉味",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 28,
    "foodName": "魷魚",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 29,
    "foodName": "黑白配",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.parknshop.com/medias/sys_master/front/prd/9153462534174.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 30,
    "foodName": "144g(迷你袋庄)麥提沙",
    "category": "SNACK",
    "price": 3,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://y.zdmimg.com/201704/21/58f9cfe6387d34113.png",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 31,
    "foodName": "31.8g 多樂脆粟米片。芝士(019903)",
    "category": "SNACK",
    "price": 5,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://images-na.ssl-images-amazon.com/images/I/71MQeIS7FAL._SL1500_.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 32,
    "foodName": "(30包裝)25g卡樂B薯片-熱浪",
    "category": "SNACK",
    "price": 5,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://images.hktv-img.com/images/HKTV/10856/LS_0680_24_product_39307425_20200506173110_01_515.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 33,
    "foodName": "35g 淮鹽花生",
    "category": "SNACK",
    "price": 7,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://cdn.ztore.com/images/ztore/production/product/750px/1013247_1.jpg?1559074865",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 34,
    "foodName": "14.2g藍鑽石杏仁-蜜糖焗杏仁",
    "category": "SNACK",
    "price": 7,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://3.bp.blogspot.com/-mmMcw6p_cmQ/U73yPPZX7rI/AAAAAAAABHU/ISja9QnXpEw/s1600/3.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 35,
    "foodName": "10盒裝泰國固力果百力滋-燒烤",
    "category": "SNACK",
    "price": 7,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://lh3.googleusercontent.com/proxy/fSh7ndr129O-iaOq_4gTrZQL_6AIXENTqwhJGJtCAXWODZh0LCm37QQ6DlF-dYZS1rcViupMuNx7PicfuTntMO5RjL2CPCEP4LjqS_yB4TftloDG0VVpgd5VEUwunJCMw0AwvU8M",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 36,
    "foodName": "百邦迷你條裝-清湯味薯片",
    "category": "SNACK",
    "price": 7,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://cdn.ztore.com/images/ztore/production/product/400px/1005227_1.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 37,
    "foodName": "40g卡樂B蝦條。原味",
    "category": "SNACK",
    "price": 7,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://imgservice.suning.cn/uimg1/b2c/image/xR15uouPBHxJgE8rRZkd5w.jpg_400w_400h_4e",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 38,
    "foodName": "M&M's朱古力-花生",
    "category": "SNACK",
    "price": 8,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://imgservice.suning.cn/uimg1/b2c/image/Kr4oYZLfQRYCZ8vFkMwItw.jpg_800w_800h_4e",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 39,
    "foodName": "(8罐裝)53g品客薯片-忌廉洋蔥",
    "category": "SNACK",
    "price": 9,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://assets.lyreco.com/is/image/lyrecows/2018-8343691?locale=HK_hk&id=A0Dqu3&fmt=jpg&fit=constrain,1&wid=430&hei=430",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 40,
    "foodName": "110g德國橡皮軟糖。草莓士的",
    "category": "SNACK",
    "price": 10,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.ckf.com.hk/image/cache/data/wfd174360-500x500.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  },
  {
    "id": 41,
    "foodName": "110g德國橡皮軟糖。酸砂可樂",
    "category": "SNACK",
    "price": 10,
    "unit": "包",
    "quantity": 10,
    "imageUrl": "https://www.ckf.com.hk/image/cache/data/wfd550010d-500x500.jpg",
    stocks: [],
    inventories: [],
    //salesVolumes:[{}]
  }
];

//Testing
/*
{
  products{
    .....
    stocks(stockLocation:$stockLocation){ //[StockRecord]!
        location
        quantity
        histories{ //[StockRecordHistory]!
          date
          location
          addedQuantity
        }
    }
    inventories(location:$location){ //[InventoryRecord]!
      location
      quantity
      histories{ //[InventoryRecordHistory]!
        date
        location
        addedQuantity
      }
    }
    salesVolumes(location:$location){

    }
  }
}
*/
export const testData = {
  products: { ...foodInfoList }
}