import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentSection from './stripePayment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

export default function StripeContainer(props:any){
    const {totalPrices,location,shoppingCart,resetHandler} = props;
    return (
    <Elements stripe={stripePromise}>
        <PaymentSection 
        totalPrices = {totalPrices} 
        location = {location} 
        shoppingCart = {shoppingCart}
        resetHandler = {resetHandler}/>
    </Elements>
    );
}