import React from 'react';
import DragableList from './dragableList';
import styles from './snack.module.scss';

export default function Snack(props:any){
    const {foodList} = props;
    return (<div className={styles.snackSection}>
        <div className={styles.topTitle}>
          <span>Snack</span>
        </div>
        <div className={styles.topSectionContentBorder2}>
        <DragableList 
            foodList={foodList}
            category="SNACK"
            droppableId="snackList"
            ></DragableList>
        </div>
      </div>);
}