import React, { useState } from 'react';
import Header from '../shop/header';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './addCash.module.scss';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch,useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { STORE_LOCATIONS } from '../common/parameters';
import { addCashRecord } from '../tools/queries';
import fetch from '../tools/graphqlFetch';
import { IRootState } from '../store';

export default function AddCash() {
    const [error, setError] = useState(undefined);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
    const onSubmit = async (data: any) => {
        const record = {
            storeLocation:data.invLocation,
            productId: 0,
            quantity: 0,
        }
        const variables = {
            record,
            totalAmount: parseInt(data.amount) * 100,
            tokenId: data.date
        }

        const paymentResult = await fetch(addCashRecord, variables);
        if (paymentResult.errors) {
            setError(paymentResult.errors);
        } else {
            setError(undefined);
            dispatch(push("/stock"));
        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.topSection}>
                <div className={styles.row} ><Header login={isAuthenticated} location={''} /></div>
            </div>
            <Container className={styles.contentSection}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col className={styles.title}>
                            <label>Add Cash Record</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <label>Inventory Location</label>
                        </Col>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <select name="invLocation" ref={register({ required: true })}>
                                {
                                    STORE_LOCATIONS.map(item=>(<option value={item}>{item}</option>))
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <label>Amount</label>
                        </Col>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <input type="number" placeholder="Amount" name="amount" ref={register({ required: true })} />
                            {errors.amount && <p>{errors.amount.message}</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <label>Date</label>
                        </Col>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <input type="month" placeholder="date" name="date" ref={register({ required: true, max: 12, min: 1 })} />

                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.rowStyle} style={{ marginBottom: "20px" }}>
                            <input type="submit" />
                        </Col>
                    </Row>

                </form>
                {error ? <label>{error}</label> : ""}
            </Container>
        </div>);
}