import React from 'react';
import Header from '../shop/header';
import Menu from '../stock/menu';
import {useForm} from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './newFood.module.scss';
import { useTranslation } from 'react-i18next'
import {addItemQuery} from '../tools/queries';
import {multiplePartGraphQLFetch} from '../tools/graphqlFetch';
import {CATEGORIES,UNITS} from '../common/parameters';
import { Container, Row, Col } from 'reactstrap';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';

export default function NewFoodForm(){
    const {t} = useTranslation();
    const {register, handleSubmit, errors} = useForm();
    const dispatch = useDispatch();
    const onSubmit = async (data:any)=>{
        //form data = data
        //file = data.image[0]);
        const formData = {
            foodName:data.foodName,
            category:CATEGORIES[parseInt(data.category)],
            price:parseInt(data.price),
            unit:UNITS[parseInt(data.unit)]
        }
        /*const result = */await multiplePartGraphQLFetch(addItemQuery,formData,data.image[0]);
        //TODO: Successful Case Handling
        dispatch(push("/stock"));
    }
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.row} >
                        <Header login={true} location={``} />
                    </div>
                </div>
                <div className={styles.overlapDiv}>
                    <div className={styles.contentSection}>
                        <Container className={styles.form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                <div className={styles.formRowSubmit}>
                                    <div className={styles.formTitle}>Create Item Form</div>
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" className={styles.formRow} ><div><label>Item Name</label></div></Col>
                                <Col xs="12" sm="6" className={styles.formRow}>
                                <div>
                                            <input name="foodName"
                                                placeholder="Item Name"
                                                ref={register({ required: true, maxLength:100 })} />
                                            {errors.foodName && <span>This field is required.</span>}
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" className={styles.formRow}><div><label>Category</label></div></Col>
                                <Col xs="12" sm="6" className={styles.formRow}>
                                <div>
                                            <select name="category" ref={register({required:true})}>
                                                <option value="0">Drink</option>
                                                <option value="1">Snack</option>
                                                <option value="2">Noodles</option>
                                            </select>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" className={styles.formRow}><div><label>Price</label></div></Col>
                                <Col xs="12" sm="6" className={styles.formRow}>
                                <div>
                                            <input type="number" name="price"
                                            placeholder="Price" ref={register({required:true,min:0})}/>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" className={styles.formRow}><div><label>Unit</label></div></Col>
                                <Col xs="12" sm="6" className={styles.formRow}>
                                <div>
                                            <select name="unit" ref={register({required:true})}>
                                                <option value="0">{t('CAN')}</option>
                                                <option value="1">{t('PACK')}</option>
                                                <option value="2">{t('BOTTLE')}</option>
                                                <option value="3">{t('BAG')}</option>
                                                <option value="3">{t('CUP')}</option>
                                            </select>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" className={styles.fileBg}>
                                    <div className={styles.formRowFileTop}><label>Upload Image</label></div>
                                </Col>
                                <Col xs="12" sm="6" className={styles.fileBg}>
                                <div className={styles.formRowFileTop}>
                                                <input ref={register} type="file" name="image" />
                                            </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div className={styles.formRowSubmit}>
                                        <input type="submit" />
                                    </div>

                                </Col>
                            </Row>
                        </form>
                        </Container>
                    </div>
                    <div className={styles.bottomSection}>
                        <Menu />
                    </div>
                </div>
            </div>
        </div>
    )
}