/*
References:
https://stackoverflow.com/questions/49801635/stripeelements-wont-style
https://stripe.com/docs/payments/integration-builder
https://medium.com/better-programming/stripe-api-tutorial-with-react-and-node-js-1c8f2020a825
https://stripe.com/docs/api/payment_intents/confirm?lang=node
https://stripe.com/docs/payments/intents#intent-statuses
https://stripe.com/docs/stripe-js/react#element-components
https://stripe.com/docs/stripe-js
https://stripe.com/docs/payments/payment-intents
https://stripe.com/docs/payments/accept-a-payment?integration=elements
https://stripe.com/docs/api/payment_intents
https://medium.com/better-programming/stripe-api-tutorial-with-react-and-node-js-1c8f2020a825
https://stripe.com/docs/payments/integration-builder
 */

import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './stripePayment.module.scss';
import { Stripe, StripeElements,StripeCardElement, PaymentIntent } from "@stripe/stripe-js";
import  {paymentQuery } from '../tools/queries';
import fetch from '../tools/graphqlFetch';
import * as R from 'ramda';
import {locationIdxMenu,STORE_LOCATIONS} from '../common/parameters';
import { Container, Row, Col } from 'reactstrap';

export default function StripePayment(props: any) {
    const stripe = useStripe() as Stripe;
    const elements = useElements() as StripeElements;
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error,setError] = useState<string|null>(null);
    const [refNo, setRefNo] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [disableComponent, setDisableComponent] = useState(true);
    const { totalPrices,location,shoppingCart,resetHandler } = props;
    
    useEffect(()=>{
        if (totalPrices > 0){
            setDisableComponent(false);
        }else{
            setDisableComponent(true);
        }
    },[totalPrices]);
    
    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event: any) => {
        setDisabled(event.empty);
        setError(event.error? event.error.message : "");
    };

    const handleSubmit = async (ev:any)=>{
        ev.preventDefault();
        setProcessing(true);
        // const response = await fetch(process.env.REACT_APP_BACKEND_URL+'/stripe/create-payment-intent', {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify(
        //         { 
        //            "totalPrice": totalPrices * 100,
        //            location,
        //            shoppingCart
        //         })
        // });
        // const {clientSecret} = await response.json();
        // const payload = await stripe.confirmCardPayment(clientSecret,{
        //     payment_method:{
        //         card: elements.getElement(CardElement) as StripeCardElement
        //     },

        // });
        // if(payload.error){
        //     setError(`${payload.error.message}`);
        //     setProcessing(false);
        //     setPaymentStatus((payload.error.payment_intent as PaymentIntent).status);
        // }else{
        //     setError(null);
        //     setProcessing(false);
        //     const {id,created,status} = payload.paymentIntent as PaymentIntent;
        //     setRefNo(created.toString()); //Use created data as reference no.
        //     setPaymentStatus(status);
        //     //TODO: create the payment to GraphQL server
        //     console.log(id,created);
        // }

        const card =  elements.getElement(CardElement);
        const result = await stripe.createToken(card as StripeCardElement);
        
        if (result.error) {
            //console.log(result.error.message);
            setError(`${result.error.message}`);
            setProcessing(false);
            if (result.error.payment_intent){
                setPaymentStatus((result.error.payment_intent as PaymentIntent).status);
            }
        } else {
            const records:any = R.map(product=>({
                storeLocation:STORE_LOCATIONS[locationIdxMenu[location]],
                productId:parseInt(R.path(['food','id'],product) as string),
                quantity:parseInt(R.path(['quantityInCart'],product) as string)
              }),shoppingCart);
            // console.log(result.token?.id);
            // pass the token to your backend API
            const variables ={
                records,
                totalAmount:  totalPrices * 100,
                paymentMethod: "CREDIT_CARD",
                tokenId: result.token?.id
            }

            const paymentResult = await fetch(paymentQuery,variables);
            if (paymentResult.errors){
                setError(paymentResult.errors[0].message);
                setProcessing(false);
                setPaymentStatus("DECLINED");//APPROVED / DECLINED   
            }else{
                setError(null);
                setProcessing(false);
                setPaymentStatus(paymentResult.data.payment.approval);//APPROVED / DECLINED
                setRefNo(paymentResult.data.payment.refNo);
            }
        }
        // setProcessing(false);
        setSucceeded(true);
    }
    return (
        <Container className={styles.paymentSection}>
            <Row>
                <Col className={styles.paymentTitle}>
                    <div className={styles.paymentTitleBackground}>
                        <span>Payment</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                <div className={styles.paymentContent}>
                <div className={styles.paymentPrice}>
                    <span>HKD ${totalPrices}</span>
                </div>
                <div className={disableComponent ? styles.stripeContentHide : styles.stripeContent}>
                    <form onSubmit={handleSubmit}>
                        <CardElement id="cardElement" className={styles.cardElement} options={cardStyle} onChange={handleChange} />
                        <button disabled={processing || disabled || succeeded } id="submit">
                            <span id="buttonText">
                                {processing ? (
                                    <div className={styles.spinner} id="spinner"></div>
                                ) : (
                                        "Pay Now"
                                    )}
                            </span>
                        </button>
                        {/* Show a success message upon completion */}
                        {
                            (succeeded && paymentStatus === "APPROVED") && (<div style={{display:"flex",justifyContent:"center",alignItems:"center",opacity:0.9,zIndex:9999,background:"black",width:"100%",height:"100%",position:"fixed",left:"0px",right:"0px",top:"0px",bottom:"0px"}}>
                            <div className={succeeded && paymentStatus === "APPROVED" ? styles.resultMessage : styles.hidden} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",opacity:1,zIndex:10000,background:"white",width:"300px",height:"200px",padding:"10px 10px 10px 10px",borderRadius:"10px"}}>
                               <div>Payment succeeded! <br/>Ref. No is {refNo}.</div>
                               <button style={{borderRadius:"10px"}} onClick={()=>{
                                   const card =  elements.getElement(CardElement);
                                   card?.clear();
                                   setSucceeded(false);
                                   setPaymentStatus("");
                                   setProcessing(false);
                                   resetHandler();
                               }}>OK</button>
                            </div>
                        </div>)
                        }
                        {
                            (succeeded && paymentStatus !== "APPROVED") && (<div style={{display:"flex",justifyContent:"center",alignItems:"center",opacity:0.9,zIndex:9999,background:"black",width:"100%",height:"100%",position:"fixed",left:"0px",right:"0px",top:"0px",bottom:"0px"}}>
                                <div className={succeeded && paymentStatus !== "APPROVED" ? styles.resultMessage : styles.hidden} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",opacity:1,zIndex:10000,background:"white",width:"300px",height:"200px",padding:"10px 10px 10px 10px",borderRadius:"10px"}}>
                                    <div>{error}</div>
                                   <button style={{borderRadius:"10px"}} onClick={()=>{
                                       const card =  elements.getElement(CardElement);
                                       card?.clear();
                                       setSucceeded(false);
                                       setPaymentStatus("");
                                       setProcessing(false);
                                   }}>OK</button>
                                </div>
                            </div>)
                        }
                    </form>
                </div>
                <div className={disableComponent ? styles.stripeLabelHide : styles.stripeLabel}>
                    <span>Powered by Stripe</span>
                </div>
            </div>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
        </Container>
    );
}