export default async function graphql_fetch(queryString:any,variables:any) {
    const body = {
        query:queryString,
        variables
    }
    const queryRes = await fetch(process.env.REACT_APP_BACKEND_URL+"/graphql",{
        method: "POST",
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify(body)
    });
    const result = await queryRes.json();
    return result;
}
/*
References:
https://www.floriangaechter.com/blog/graphql-file-uploading/
*/
export async function multiplePartGraphQLFetch(queryString:any,variables:any,file:any) {
    const formData = new FormData();
    const fullQuery = {
        query:queryString,
        variables:{
            item:{...variables},
            file:null
        }
    }
    formData.append("operations",JSON.stringify(fullQuery));
    const map = {"0":["variables.file"]};
    formData.append("map",JSON.stringify(map));
    formData.append("0",file);

    const queryRes = await fetch(process.env.REACT_APP_BACKEND_URL+"/graphql",{
        method:"POST",
        body:formData
    });
    const result = await queryRes.json();
    return result;
}