import React from 'react';
import Header from '../shop/header';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './login.module.scss';
import { Container, Row, Col } from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {login,loginFail} from '../inventory/action/action';
import { IRootState } from '../store';

export default function Login() {
    const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const onSubmit = async (data:any) => {
        const res = await fetch(process.env.REACT_APP_BACKEND_URL+"/login",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({username:data.username,password:data.password})
        });
        const ans = await res.json();
        if (ans.success){
            dispatch(login());
            dispatch(push("/stock"));
        }else{
            dispatch(loginFail());

        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.topSection}>
                <div className={styles.row} ><Header login={false} location={''} /></div>
            </div>
            <Container className={styles.bottomSection}>
                {/*<div className={styles.instructionDiv}>Please select login methods:</div>
                <div className={styles.loginPanel}>
                    <a href="/stock">
                        <button>Google Login</button>
                </a>
                </div>*/}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <label>User Name</label>
                        </Col>
                        <Col xs="12" sm="6"  className={styles.rowStyle}>
                            <input type="text" placeholder="User Name" name="username" ref={register({ required: true, maxLength: 80 })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <label>Password</label>
                        </Col>
                        <Col xs="12" sm="6" className={styles.rowStyle}>
                            <input
                                name="password"
                                type="password"
                                ref={register({
                                    required: "You must specify a password",
                                    minLength: {
                                        value: 8,
                                        message: "Password must have at least 8 characters"
                                    }
                                })}
                            />
                            {errors.password && <p>{errors.password.message}</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col  className={styles.rowStyle}>
                            <input type="submit" />
                        </Col>
                    </Row>

                </form>
                {isAuthenticated === false ? <label>Wrong username/password</label>:""}
            </Container>
        </div>);
}