/*
https://www.npmjs.com/package/randomcolor
https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/VerticalBar.js
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
 */
import React, { useEffect,useState } from 'react';
import Header from '../shop/header';
import Menu from '../stock/menu';
import { Bar } from 'react-chartjs-2';
import { locationMap } from '../common/parameters';
import styles from './salesVolumes.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {IRootState} from '../store';
import randomColor from 'randomcolor';
import {Dropdown} from '../common/dropdown';
import {locationMenu} from '../common/parameters';
import {push} from 'connected-react-router';

//Test Data
let initData = {
    labels: [] as any,
    datasets: [
        {
            label: 'Sales Volumes each Item within 3 months',
            data: [] as any,
            backgroundColor: [] as any,
            borderColor: [] as any,
            borderWidth: 1,
        },
    ],
}

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

export default function SalesVolumes(props: any) {
    const { match: { params: { location } } } = props;
    const foodInfoList = useSelector((state: IRootState) => state.tecStore.tecStore);
    const [data, setData] = useState(initData);
    const dispatch = useDispatch();

    useEffect(() => {
        let newData = { ...data };
        const foodNames = foodInfoList.map(f=>f.foodName);
        const quantities = foodInfoList.map(f=>Math.floor(Math.random() * Math.floor(100))); //qty
        newData.labels = foodNames;
        newData.datasets[0].data = quantities;        
        const bgColors = foodInfoList.map(f=>randomColor({
            luminosity: 'random',
            format: 'rgba',
            alpha: 0.2
        }));
        const borderColors = foodInfoList.map(f=>randomColor({
            luminosity: 'dark',
            format: 'rgba',
            alpha: 1
        }));
        newData.datasets[0].backgroundColor = bgColors;
        newData.datasets[0].borderColor = borderColors;
        setData(newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.row} >
                        <Header login={true} location={`${locationMap[location]}`} />
                    </div>
                </div>
                <div className={styles.overlapDiv}>
                    <div className={styles.bottomSection}>
                        <Menu />
                    </div>
                    <div className={styles.contentSection}>
                        <div className={styles.locationBox}>
                            <Dropdown renderTrigger={({isOpen,toggle})=>(
                                <button className="button" onClick={toggle}>{locationMenu[location]}</button>
                            )}
                            
                            renderContent = {({close})=>(
                                <ul className="menu-list">
                                    {
                                        Object.keys(locationMenu).map((siteKey)=>(
                                            <li onClick={()=>{
                                                dispatch(push(`/salesVolumes/${siteKey}`));
                                            }}>
                                                <a href="#0">
                                                    {locationMenu[siteKey]}
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            )}
                            />
                        </div>
                        <Bar data={data} options={options} />
                    </div>
                </div>
            </div>
        </div>)
}