import {initialState} from '../src/inventory/reducer/reducer';

export default class StateLoader{
    public loadState(){
        try{
            let serializedState = localStorage.getItem("tecStore");
            if (serializedState === null){
                return initialState;
            }
            return JSON.parse(serializedState);
        }catch{
            return initialState;
        }
    }

    public saveState(state:any){
        try{
            let serializedState = JSON.stringify(state);
            localStorage.setItem("tecStore",serializedState);
        }catch{

        }
    }
}