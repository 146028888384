import React, { useEffect,Suspense } from 'react';
import { Provider } from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import store, {history,stateLoader} from './store';
import { Route, Switch } from 'react-router-dom';
import SelectLocation from './shop/location';
import ShopPage from './shop/shop';
import LoginPage from './login/login';
import StockPage from './stock/stock';
import AddInventoryPage from './inventory/addInventory';
import SalesVolumesPage from './salesVolumes/salesVolumes';
import AddFoodPage from './newFood/newFood';
import TransactionRecordsPage from './payment/transactionRecords';
import AddCash from './payment/addCash';
import NotFoundPage from './notFound';
import './App.css';
import i18n from './tools/i18n';
import {PrivateRoute} from './PrivateRoute';

function App() {
  /*
TODO: add multiple language in the future, now default zh-hk first

Reference:
https://medium.com/@ricklee_10931/react-multi-lingual-with-react-i18next-57879f986168
*/
useEffect(() => {
i18n.changeLanguage("zh");
},[]);

//Reference:
//https://stackoverflow.com/questions/37195590/how-can-i-persist-redux-state-tree-on-refresh
store.subscribe(()=>{
  //console.log(store.getState().tecStore);
  stateLoader.saveState(store.getState().tecStore);
});

  return (
    <Suspense fallback={null}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" exact={true} component={SelectLocation}/>
          <Route path="/shop/:location" exact={true} component={ShopPage}/>
          <Route path="/login" component={LoginPage}/>
          <PrivateRoute path="/stock" component={StockPage}/>
          <PrivateRoute path="/addInventory/:stockLocation" component={AddInventoryPage}/>
          <PrivateRoute path="/salesVolumes/:location" component={SalesVolumesPage}/>
          <PrivateRoute path="/addItem" component={AddFoodPage}/>
          <PrivateRoute path="/addCash" component={AddCash}/>
          <PrivateRoute path="/transactionsRecords" component={TransactionRecordsPage}/>
          <Route component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    </Provider>
    </Suspense>
  );
}

export default App;
