import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './shoppingCartList.module.scss';
import BoughtItem from '../model/boughtItem';
import NumericInput from 'react-numeric-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { IRootState } from '../store';
import Food from '../model/food';

export default function ShoppingCartList(props: any) {
    const {t} = useTranslation();
    let foodList = useSelector((root:IRootState)=>root.tecStore.tecStore) as any;
    let { shoppingCart, removeItemFromCart, onQuantityChanged } = props;
    return (<div className={styles.shoppingCartSection}>
        <div className={styles.shoppingCartTitle}>
            <span>Shopping Cart (Provide each item's quantity in Shopping Cart)</span>
        </div>
        <div className={styles.shoppingCartList}>
        <Droppable droppableId="shoppingList" key="shoppingList">
            {(shoppingCartProvider) => (
                shoppingCart.length === 0 ?
                    <div className={styles.shoppingCartListPlaceHolder}
                        ref={shoppingCartProvider.innerRef}
                        {...shoppingCartProvider.droppableProps}>
                        <span>Drag and Drop item here</span>
                    </div> :
                    <div className={styles.shoppingCartListSection} ref={shoppingCartProvider.innerRef}
                        {...shoppingCartProvider.droppableProps}>
                        {
                            shoppingCart.map((item: BoughtItem, index: number) => {
                                const idx = foodList.findIndex((food:Food)=>food.id === item.food.id);
                                console.log(foodList[idx].inventory.remainQuantity);
                                return (<Draggable
                                    draggableId={`shopcart_${item.food.id}`}
                                    key={`shopcart_${item.food.id}`}
                                    index={index}>
                                    {(shoppingCartProvider) => (
                                        <div
                                            key={`shopcart_${item.food.id}`}
                                            ref={shoppingCartProvider.innerRef}
                                            {...shoppingCartProvider.draggableProps}
                                            {...shoppingCartProvider.dragHandleProps}
                                            className={styles.shoppingCartContent}>
                                            <div className={styles.shoppingCartContentImg}>
                                                <img src={item.food.imageUrl} alt="" />
                                            </div>
                                            <div className={styles.shoppingCartContentInfo}>
                                                <div className={styles.shoppingCartContentInfoSpan}><span>{item.food.foodName}</span></div>
                                                <div className={styles.shoppingCartContentInfoSpan}><span>${item.food.price}/{t(`${item.food.unit}`)}</span></div>
                                            </div>
                                            <div className={styles.shoppingCartContentQty}>
                                                <NumericInput
                                                    onKeyDown={event=>{event.preventDefault()}}
                                                    onChange={(value: number | null, strValue: string, sender: HTMLInputElement) => { 
                                                        onQuantityChanged(value as number, index);
                                                    }}
                                                    className={styles.shoppingCartContentQtyDrp}
                                                    min={1} max={foodList[idx].inventory.remainQuantity + item.quantityInCart} value={item.quantityInCart} />
                                            </div>
                                            <div className={styles.shoppingCartContentDelBut}>
                                                <button onClick={() => { removeItemFromCart(index) }}>
                                                    <FontAwesomeIcon className={styles.shoppingCartContentDelButIco}
                                                        icon={faTrashAlt} />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>)
                            })
                        }
                        {shoppingCartProvider.placeholder}
                    </div>
            )}
        </Droppable></div></div>);
}