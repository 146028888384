import React, { useState } from 'react';
import { Planet } from 'react-planet';
import { locationMenu } from '../common/parameters';
import styles from './locationMenu.module.scss';

export default function LocationMenu(props:any) {
    const {onLocationSelected} = props;
    const [location, setLocation] = useState("tw27");
    const locationSelected = (key: string) => {
        setLocation(key);
        onLocationSelected(key);
    }
    return (<Planet centerContent={<div className={styles.centerMenu}>{locationMenu[location]}</div>}
        dragablePlanet
        dragRadiusPlanet={20}
        bounce
        hideOrbit
        autoClose
        orbitRadius={200}
        bounceOnClose
        rotation={380}
        bounceDirection="BOTTOM">
            {
                Object.keys(locationMenu).map(key=> (<div key={key} className={styles.satellite}>
                    <button onClick={() => locationSelected(key)}>{locationMenu[key]}</button>
                </div>))
            }
        <div />
        <div />
        <div />
        <div />
        <div />
    </Planet>);
}