import React from 'react';
import styles from './snackInfoBlock.module.scss';

export default function SnackInfoBlock(props: any) {
    const { food } = props;
    const remainQuantity = food.inventory?food.inventory.remainQuantity:0;
    const quantity = food.inventory?food.inventory.quantity:0;
    const stockQtyRatio = (remainQuantity/(quantity?quantity:1))*100.0;
    const salesQtyRatio = 100.0 - stockQtyRatio;
    return (<div className={styles.rootContainer}>
        <div className={styles.indicatorSection}>
            {
                stockQtyRatio <= 40 ?
                    <div className={styles.upper} style={{ "height": `${salesQtyRatio}%`, "backgroundColor": "red" }}></div>
                    : <div className={styles.upper} style={{ "height": `${salesQtyRatio}%` }}></div>
            }
            <div className={styles.lower} style={{ "height": `${stockQtyRatio}%` }}></div>
        </div>
        <div className={styles.imgSection}>
            <img src="/images/snack.png" alt="drink" />
        </div>
        <div className={styles.infoSection}>
            <div>{food.foodName}</div>
            <div>{remainQuantity}/{quantity}</div>
        </div>
    </div>);
}