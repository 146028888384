import React, { useEffect } from 'react';
import Header from './stockHeader';
import Menu from '../stock/menu';
import StockList from './stockList';
import InventoryPrepareList from './inventoryPrepareList';
import InventoryList from './inventoryList';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { IRootState } from '../store';
import { stockLocationList, locationMenu,STOCK_LOCATION } from '../common/parameters';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './addInventory.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateStock, addPrepareInventoryRecord } from '../inventory/action/action';

import {addStockRecordQuery} from '../tools/queries';
import fetch from '../tools/graphqlFetch';
import * as R from 'ramda';
import { Container, Row, Col } from 'reactstrap';

export default function AddInventory(props: any) {
    const isAuthenticated = useSelector((root:IRootState)=>root.tecStore.isAuthenticated);
    type StockRec = {quantity:number,location:string}
    const { match: { params: { stockLocation } } } = props;
    const foodInfoList = useSelector((rootState: IRootState) => rootState.tecStore.tecStore);
    const prepareInvList = useSelector((state:IRootState)=>state.tecStore.prepareInventories);
    const dispatch = useDispatch();
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const food = foodInfoList[result.source.index];
        const stockRec = food.stocks.filter(stock=>stock.location === STOCK_LOCATION[stockLocation]);
        if (!stockRec) return;
        if (stockRec.length === 0) return;
        if(prepareInvList[stockLocation].findIndex(element=>element.stockId === food.id) > -1) return;
        if (result.source.droppableId === "drinkList" ||
            result.source.droppableId === "snackList" ||
            result.source.droppableId === "noodlesList") {
            if (result.destination.droppableId === "prepareInvList") {
                //const food = foodInfoList[result.source.index];
                dispatch(addPrepareInventoryRecord(stockLocation, food.id, locationMenu["tw27"], 0));
            }
        }
    }

    const updatedStock = async (stockId:string, newValue: string) => {
        const variables =  {
            record: {
                stockLocation:STOCK_LOCATION[stockLocation],
                productId: parseInt(stockId),
                quantity: parseInt(newValue)
            }
        }
        const result = await fetch(addStockRecordQuery,variables);
        const newStockRec:StockRec = R.path(["data","addStockRecord"],result) as StockRec;
        dispatch(updateStock(parseInt(newValue),stockId, newStockRec.location));
    }

    return (<div className={styles.root}>
       <div>
                    <div className={styles.bottomSection}>
                        <Menu />
                    </div>
                    </div>
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.row}>
                        <Header login={isAuthenticated} location={(stockLocationList as any)[stockLocation]} /></div>
                </div>

                <Container className={styles.main} fluid={true}>
                    <Row>
                        <Col>
                            <Header login={isAuthenticated} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="4">
                            <StockList title="Drink Stock"
                                category="DRINK" droppableId="drinkList"
                                location={stockLocation}
                                updatedStock={updatedStock} />
                        </Col>
                        <Col xs="12" sm="4">
                            <StockList title="Snack Stock"
                                category="SNACK" droppableId="snackList"
                                location={stockLocation}
                                updatedStock={updatedStock} />
                        </Col>
                        <Col xs="12" sm="4">
                            <StockList title="Noodles Stock"
                                category="NOODLES" droppableId="noodlesList"
                                location={stockLocation}
                                updatedStock={updatedStock} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" style={{paddingRight:"30px"}}>
                            <InventoryPrepareList stockLocation={stockLocation} title="Prepare New Inventory List" droppableId="prepareInvList" />
                        </Col>
                        <Col xs="12" sm="6">
                        <InventoryList title="Inventory List" location={(stockLocationList as any)[stockLocation]} droppableId="invList" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </DragDropContext>
    </div>);
}