import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line
import styles from './header.module.scss';
import { Container, Row, Col } from 'reactstrap';
import {useDispatch} from 'react-redux';
import {logout} from '../inventory/action/action';

export default function Header(props:any) {
    const { login, location } = props;
    const dispatch = useDispatch();
    return (<Container>
        <Row>
            <Col xs="2">
                <div className={styles.logoSection} style={{ marginTop: "20px" }}>
                    <img src="/images/logo_title.png" alt="Tecky Logo" />
                </div>
            </Col>
            <Col xs="8"><div className={styles.siteNameSection} style={{ marginTop: "20px", marginLeft: "10px" }}>
                <div className={styles.header}> Tec 記士多 {location}</div>
            </div></Col>
            <Col xs="2">
                {
                    !login ? <div className={styles.loginSection} style={{ marginTop: "20px" }}>
                        <a href="/login">
                            <button className={styles.loginButton}>Login</button>
                        </a>
                    </div> : <div className={styles.loginSection} style={{ marginTop: "20px" }}>
                        <a href="/login">
                            <button className={styles.loginButton} onClick={()=>{
                                dispatch(logout());
                            }}>Logout</button>
                        </a>
                    </div>
                }
            </Col>
        </Row>
    </Container>);
}